import { h } from 'hyperapp';
import { Card, Enter } from '@app/elements';
import * as constants from '@app/constants';

import ResponsiveModalLayout from '@app/layouts/modal/layout';
import ViewMentionUnicancer from './subview/subview-unicancer';

export default {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <ResponsiveModalLayout page={'mentions'} closeModal={props.closeModal}>
                <div class='mentions-central' style={{ 'background-color': '#f8fafb' }}>
                    <Card classes={'btzPageCard btzBackgroundCover'} kind={'datas'} style={{ 'padding-bottom': '30px' }}>
                        <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                            <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
                                <ViewMentionUnicancer {...state}></ViewMentionUnicancer>
                            </Enter>
                        </Card.Header>
                    </Card>
                </div>
            </ResponsiveModalLayout>
        );
        return view;
    }
}