import { t } from "i18next";
import { h } from "hyperapp";
import { Component } from "@app/utils";
import { isDefined, getReadableDate, getDateObjFromYMD } from "@app/core";
import { Tile } from "@app/elements";

import ViewInstructionDefault from "./subview-instructions";

const actions = {
	//
};

const view = (state, actions) => (props, children) =>
	(
		<dummy>
			<div class="responsiveModalHeader">
				<p class="btzTitle btzXl">{t("Mentions légales")}</p>
			</div>
			<div class="responsiveModalContent thfMentionsWrapper">
				<p class="">
					{t(
						"L‘ensemble des éléments constituant notre plateforme ont été conçus en suivant de près les normes internationales les plus strictes en terme de gestion de la qualité, de sécurité du système d‘information et de la conception de logiciels dispositifs médicaux."
					)}
				</p>
				<p class="" style="text-transform: capitalize;">
					{t("Versions : ") +
						(process.env.APP_VERSIONPRODUCT || "N.C") +
						" : (Front " +
						(process.env.APP_VERSION || "N.C") +
						", API " +
						(process.env.APP_VERSIONAPI || "N.C") +
						")" +
						(isDefined(process.env.APP_YMD)
							? " - " +
							  getReadableDate(
									getDateObjFromYMD(process.env.APP_YMD).date,
									"dddd DD MMMM YYYY"
							  )
							: " - N.C")}
				</p>
				<ViewInstructionDefault {...props}></ViewInstructionDefault>
			</div>
		</dummy>
	);

export default Component({}, actions, view, "subview");
